
const Config = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "ap-south-1",
    dataUrl: "https://s3.ap-southeast-1.amazonaws.com/hub31-user-data",
    BASE_URL: 'https://api.hub31.com/rsc',
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_HVrvkesJx",
    APP_CLIENT_ID: "61gfvg62h7pbbmq90q4jkup62a",
    IDENTITY_POOL_ID: "ap-south-1:d8d34b83-320c-448d-af1f-cf12976d2667"
  },
  pubsub: {
    REGION: "ap-south-1",
    MQTT_ID: "a1ons9ps9fzo8k-ats"
  },
  agora: {
    APP_ID: "2c19a7dba449403c93ba9065ecac9325"
  },
  graphQL: {
    REGION: "ap-south-1",
    ENDPOINT: "https://7roaorqawrf5vaqidxmmi42psa.appsync-api.ap-south-1.amazonaws.com/graphql"
  }
};
export default Config;
export const MESSAGE_BOX_SCROLL_DURATION = 400;
